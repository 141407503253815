<template>
  <header>
    <template>
      <div class="nav-header" :class="$auth.$state.loggedIn? 'after':'before'"><!-- 登入前:before,  登入後:after-->
        <div class="mid_box01">
          <idxMarquee04 />
          <nuxt-link to="/" class="logo"></nuxt-link>
          <template v-if="pc_header_li === '1'">
            <div class="pc_menu_s1">
              <div class="pc_menubox01">
                <nuxt-link to="/activity/activity" class="pc_menu_sort game-gactivity">
                  <span>{{ $t('store.activity') }}</span>
                </nuxt-link>
              </div>
              <div v-for="(item, index) in MobileMeun" :key="index" class="pc_menubox01" >
                <template v-if="[1, 2, 4, 5].indexOf(item.id) >= 0">
                  <a href="javascript:void(0);" class="pc_menu_sort" :class="item.images">
                    <span>{{ $t(item.title) }}</span>
                  </a>
                </template>
                <template v-else>
                  <nuxt-link :to="item.url" class="pc_menu_sort" :class="item.images">
                    <span>{{ $t(item.title) }}</span>
                  </nuxt-link>
                </template>

                <!-- 下-->
                <div class="idx-game">
                  <div class="idx-game-sbox">
                    <button class="gameListContrl prev" @click="navSubListPrev(index)">
                      <span class="btnIcon">
                        <i class="icon icon-arrowpageleft"></i>
                      </span>

                    </button>
                    <div class="idxGameBox">
                      <div class="idxGameList">
                        <template v-for="link in item.tabs">
                          <template v-if="[6].indexOf(item.id) >= 0">
                            <div class="dn"></div>
                          </template>
                          <template v-else>
                            <a
                              @click="OpenGame(item.id, link.Id, false)"
                              :class="`game-list product_${link.Id} ${link.Maintain}`"
                            >
                              <span class="itemName">{{ link.GameName }}</span>
                              <span class="itemImg">
                                <img class="img-fluid" :src="require(`@/website/${DEF_webStyle}/images/game/desktop/product_${link.Id}.webp`)" :alt="link.GameName">
                              </span>
                              <span class="upholdBox">{{ $t('store.uphold_ing') }}</span>
                            </a>
                          </template>
                        </template>
                      </div>
                    </div>
                    <button class="gameListContrl next">
                      <span class="btnIcon">
                        <i class="icon icon-arrowpageright" @click="navSubListNext(index)"></i>
                      </span>

                    </button>
                  </div>
                </div>
                <!-- 下 -->
              </div>
            </div>
          </template>
          <template v-if="DEF_webStyle === 'fl8n' || DEF_webStyle === 'fl88'">
            <audioPlay />
          </template>
          <modNavRtB v-if="$auth.$state.loggedIn !== true" /><!-- 登入前 -->
          <allBackWalletBtn v-if="$auth.$state.loggedIn === true" /><!-- 登入後 -->
          <modNavRtA02 v-if="$auth.$state.loggedIn === true" /><!-- 登入後 -->
        </div>
      </div>
    </template>
  </header>
</template>
<script>
  //const vuei18n = require(`~/components/${process.env.DEF_language}.vue`).default;
  const audioPlay = require(`~/components/${process.env.DEF_audioplay}.vue`).default;
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import modNavRtB from "~/components/desktop/header/modNavRtB.vue";
  import allBackWalletBtn from "~/components/desktop/allBackWalletBtn.vue";
  import modNavRtA02 from "~/components/desktop/header/modNavRtA02.vue";
  import idxMarquee04 from "~/components/desktop/idxMarquee.vue";
  export default {
    components:{
      modNavRtB,
      allBackWalletBtn,
      modNavRtA02,
      idxMarquee04,
      audioPlay
    },
    data(){
      return{
        ProductId: "",
        base: [],
        showImage: false,
      }
    },
    mounted() {
      this.init();
      this.changeLang();
      this.navSubBtnHide();
    },
    methods: {
      OpenGame (CategoryId, ProductId, IsTry) {
        if ([3, 7, 8].indexOf(CategoryId) >= 0) {
          if (this.$route.path != '/game/egame' || !this.$route.query.ProductId || this.$route.query.ProductId != ProductId)
            this.$router.replace({ path: "/game/egame", query: { ProductId }});

          return;
        }

        if ([6].indexOf(CategoryId) >= 0) {
          if (this.$route.path != '/game/fish')
            this.$router.replace({ path: "/game/fish"});

          return;
        }

        let GameData = this.Product(CategoryId).find(element => element.Id === ProductId);
        if (!GameData)
          return;

        let Maintain = GameData.Maintain;
        if (Maintain != "")
          return;

        if (this.$auth.$state.loggedIn === false) {
          if (this.$route.path != '/account/login')
            this.$router.replace({ path: "/account/login" });

          return;
        }

        if (IsTry) {
          window.open(GameData.UrlTry);
          return;
        }

        if (!this.ShowTransferModal && GameData.WalletCode != 'obchess') {
          window.open(GameData.Url);
          return;
        }

        this.setPlayGameData({ WalletCode: GameData.WalletCode, Url: GameData.Url });
        this.$nextTick(() => this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle"));
        return;
      },
      init() {
        if (this.ErrorMsg != "") {
          this._showMsgBox({ title: "", msg: this.ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      },
      baseData(){
        return this.Meun.map(function(value){
          return{
            type: 'a',
            blank: value.blank,
            class: value.class,
            subtxt: value.subtxt,
            title: value.title,
            url: value.url
          }
        })
      },
      changeLang(){
        this.base = this.baseData();
      },
      navSubBtnHide(){
        let subNav = document.getElementsByClassName('idx-game');
        for (var i = 0; i < subNav.length; i++) {
          let navBox = subNav[i].getElementsByClassName('idxGameBox')[0];
          let navList = navBox.getElementsByClassName('idxGameList')[0];
          let navBoxW = navBox.offsetWidth;
          let navListW = navList.offsetWidth;
          subNav[i].getElementsByClassName('gameListContrl')[0].classList.add('disabled');
          if ( navListW <= navBoxW ) {
            subNav[i].getElementsByClassName('gameListContrl')[0].classList.add('d-none');
            subNav[i].getElementsByClassName('gameListContrl')[1].classList.add('d-none');
          }
        }
      },
      navSubListPrev(e){
        let subNav = document.getElementsByClassName('idx-game');
        let navBox = subNav[e].getElementsByClassName('idxGameBox')[0];
        let navList = navBox.getElementsByClassName('idxGameList')[0];
        let navBoxW = navBox.offsetWidth;
        let itemW = navList.getElementsByClassName('game-list')[0].offsetWidth;
        let boxScrollL = navBox.scrollLeft;
        navBox.scrollLeft -= itemW;
        subNav[e].getElementsByClassName('gameListContrl')[1].classList.remove('disabled');
        if( boxScrollL == itemW ){
          subNav[e].getElementsByClassName('gameListContrl')[0].classList.add('disabled');
        }
      },
      navSubListNext(e){
        let subNav = document.getElementsByClassName('idx-game');
        let navBox = subNav[e].getElementsByClassName('idxGameBox')[0];
        let navList = navBox.getElementsByClassName('idxGameList')[0];
        let navBoxW = navBox.offsetWidth;
        let navListW = navList.offsetWidth;
        let itemW = navList.getElementsByClassName('game-list')[0].offsetWidth;
        let boxScrollL = navBox.scrollLeft;
        console.log(itemW);
        console.log(boxScrollL);
        subNav[e].getElementsByClassName('gameListContrl')[0].classList.remove('disabled');
        navBox.scrollLeft += itemW;
        console.log(itemW);
        console.log(boxScrollL);
        if( boxScrollL == navListW - navBoxW - itemW ){
          subNav[e].getElementsByClassName('gameListContrl')[1].classList.add('disabled');

        }
      },
      ...mapActions(["_showMsgBox"]),
      ...mapMutations(["_setErrorMsg"]),
      ...mapMutations("game", ["setPlayGameData"]),
    },
    watch: {
      "$i18n.locale"(language) {
        this.changeLang();
      },
      'ErrorMsg' (ErrorMsg) {
        if (ErrorMsg && ErrorMsg != '') {
          this._showMsgBox({ title: "", msg: ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      }
    },
    computed: {
      pc_header_li() {
        return process.env.pc_header_li;
      },
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ...mapState(['WebSetting', 'ErrorMsg']),
      ...mapGetters(["Meun", "MobileMeun", "Product"]),
      ...mapGetters('webSetting', ['ShowTransferModal'])
    }
  }
</script>
