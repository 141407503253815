<template>
  <div class="idxInfo idxInfo08">
    <div class="container">
      <h2 class="title">
        <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/idx-title-service.webp`)" fluid alt="頂尖優質服務"></b-img>
      </h2>
      <section class="serviceList">
        <ul>
          <li v-for="(item, index) in idxService" :key="index">
            <div class="item">
              <div class="bgImg">
                <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/idx-service-itembg.webp`)" fluid alt="頂尖優質服務"></b-img>
              </div>
              <div class="itemArticle">
                <div class="itemImg">
                  <b-img :src="require(`@/website/${DEF_webStyle}/images/${item.itemImg}.webp`)" fluid alt="頂尖優質服務"></b-img>
                </div>
                <h3 class="itemTitle">{{item.itemTitle}}</h3>
                <div class="itemContent" v-html="item.itemContent"></div>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import { getJsonFile } from '~/utils/jsonFile';
  var idxService = [];
  try { idxService = require("$json/idxService.json"); } catch (e) {}
  export default {
    data() {
      return {
        idxService,
      };
    },
    components: {
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      }
    }
  };
</script>
