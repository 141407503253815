<template>
  <div id="idxSlider" class="idxSlider banner">
    <b-carousel
      v-model="slide"
      id="carousel-1"
      :interval="2500"
      controls
      indicators
      img-width="1024"
      img-height="480"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="(item, index) in SlideshowList" :key="index">
        <template #img>
          <template v-if="!item.has_url">
            <a href="javascript:void(0)">
              <img :src="onSlideShow(index, item.pc_image)" :alt="item.name" :title="item.name">
            </a>
          </template>
          <template v-else-if="item.has_protocol || item.has_blank">
            <a :href="item.url" :target="item.blank">
              <img :src="onSlideShow(index, item.pc_image)" :alt="item.name" :title="item.name">
            </a>
          </template>
          <template v-else>
            <nuxt-link :to="item.url">
              <img :src="onSlideShow(index, item.pc_image)" :alt="item.name" :title="item.name">
            </nuxt-link>
          </template>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  export default {
    head() {
      // let link = [];
      // this.SlideshowList.forEach((item, index) => {
      //   link.push({ rel: 'preload', as:'image', href: item.pc_image });
      // });
      // return { meta: [], link: link, script: [] };
    },
    data() {
      return {
        slide: 0,
        sliding: null,
        slideId: []
      }
    },
    methods: {
      onSlideShow(index, image) {
        if (this.slideId.indexOf(this.slide) < 0)
          this.slideId.push(this.slide);

        if (this.slideId.indexOf(this.slide + 1) < 0)
          this.slideId.push(this.slide + 1);

        if (this.slideId.indexOf(this.slide - 1) < 0)
          this.slideId.push(this.slide - 1);

        if (this.slide == 0 && this.slideId.indexOf(this.SlideshowList.length - 1) < 0)
          this.slideId.push(this.SlideshowList.length - 1);

        return this.slideId.indexOf(index) >= 0 ? image : '';
      },
      onSlideStart(slide) {
        this.sliding = true;
      },
      onSlideEnd(slide) {
        this.sliding = false;
      }
    },
    computed: {
      ...mapState("slideshow", ["SlideshowList"]),
    }
  };
</script>