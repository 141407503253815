<template>
  <div class="nav-right">
    <nuxt-link to="/account/login" class="btn btn-log">
      <span>{{ $t("store.btn.login") }}</span>
      <span>{{ $t("store.btn.login_en") }}</span>
    </nuxt-link>
    <nuxt-link to="/account/signup" class="btn btn-join">
      <span>{{ $t("store.btn.sign") }}</span>
      <span>{{ $t("store.btn.sign_en") }}</span>
    </nuxt-link>
  </div>
</template>
<script>
export default {
  name: "modNavRtB",
  data(){
    return {
    }
  },
  computed:{}
};
</script>