<template>

  <div class="btn-template">

    <a v-if="value.type == 'a'" :href="WebSetting.LINELINK" :class="value.btnStyle" target="_blank">
      <i :class="value.iconStyle"></i>
      <span>{{ $t(value.name1) }}</span>
      <span class="lind_id" v-if="value.show">{{ WebSetting.LINEID }}</span>
    </a>

    <b-button v-if="value.type == 'b'" v-show="value.show" :to="value.to" @click="btnFlextoggleModal( value.needLogin, value.click[0], value.click[1])" :class="value.btnStyle">
      <i :class="value.iconStyle"></i>
      <span>{{ $t(value.name1) }}</span>
      <span>{{ $t(value.name2) }}</span>
    </b-button>

    <template v-if="value.type == 'c'">
      <b-button :class="value.btnStyle">
        <i :class="value.iconStyle"></i>
        <span>{{ $t("store.customer.service") }}</span>
        <div class="lkservice_box">
          <div class="lkservice_box-s1">
            <b-button v-if="HasLivechat" :href="WebSetting.LIVECHAT" class="btn-service" target="_blank"></b-button>

            <b-button v-if="HasLineLink" :href="WebSetting.LINELINK" target="_blank" class="btn-line" :data-text='$t("store.customer.service_line")'></b-button>

            <b-button v-if="HasTelegram" :href="WebSetting.TELRGRAM" target="_blank" class="btn-telegram" :data-text='$t("store.customer.telegram")'></b-button>

            <b-button v-if="HasFacebook" :href="WebSetting.FACEBOOK" target="_blank" class="btn-facebook" :data-text='$t("store.customer.facebook")'></b-button>

            <b-button v-if="HasTwitter" :href="WebSetting.TWITTER"  target="_blank" class="btn-twitter" :data-text='$t("store.customer.twitter")'></b-button>

            <b-button v-if="HasInstagram" :href="WebSetting.INSTAGRAM"  target="_blank" class="btn-instagram" :data-text='$t("store.customer.instagram")'></b-button>

            <b-button v-if="HasWhatsApp" :href="WebSetting.WHATSAPP"  target="_blank" class="btn-whatsapp" :data-text='$t("store.customer.whatsapp")'></b-button>

            <b-button v-if="HasNewIn" :href="WebSetting.NEWIN" target="_blank" class="btn-newin" :data-text='$t("store.customer.service_tw")'></b-button>
          </div>
        </div>
      </b-button>
    </template>

    <a v-if="value.type == 'd'" :href="value.url" :class="value.btnStyle" target="_blank">
      <i :class="value.iconStyle"></i>
      <span>{{ $t(value.name1) }}</span>
    </a>

  </div>

</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
    };
  },
  name: "userModBtn",
  props: {
    value: Object,
  },
  methods: {
    btnFlextoggleModal(status, modal, btnTag) {
      if (modal == "off")
        return ;
      if (status && this.$auth.$state.loggedIn === false)
        this.$router.push({ name: 'account-login', params: null, query: null });
      else
        this.$root.$emit("bv::toggle::modal", modal, btnTag);
    },
    toggleModal() {
      document.getElementById("livechat").classList.toggle("show");
    },
  },
  computed: {
    HasLivechat(){
      return Object.keys(this.WebSetting).indexOf('LIVECHAT') && this.WebSetting.LIVECHAT;
    },
    HasLineLink(){
      return Object.keys(this.WebSetting).indexOf('LINELINK') && this.WebSetting.LINELINK;
    },
    HasTelegram(){
      return Object.keys(this.WebSetting).indexOf('TELRGRAM') && this.WebSetting.TELRGRAM;
    },
    HasInstagram(){
      return Object.keys(this.WebSetting).indexOf('INSTAGRAM') && this.WebSetting.INSTAGRAM;
    },
    HasTwitter(){
      return Object.keys(this.WebSetting).indexOf('TWITTER') && this.WebSetting.TWITTER;
    },
    HasFacebook(){
      return Object.keys(this.WebSetting).indexOf('FACEBOOK') && this.WebSetting.FACEBOOK;
    },
    HasWhatsApp(){
      return Object.keys(this.WebSetting).indexOf('WHATSAPP') && this.WebSetting.WHATSAPP;
    },
    HasNewIn(){
      return Object.keys(this.WebSetting).indexOf('NEWIN') && this.WebSetting.NEWIN;
    },
    ...mapState(["WebSetting"]),
  }
};
</script>
