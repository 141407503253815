<template>
  <div v-if="showType == 'svg'" v-html="QrSvg"/>
  <div v-else-if="showType == 'img'" >
    <img v-show="QrSrc" :src="QrSrc">
  </div>
</template>

<script>
  import QrCode from 'qrcode';
  export default {
    components: {
      QrCode
    },
    props: {
      value: { type: String, default: null },
      size: { type: String, default: 'img' },
      showType: { type: String, default: 'img' }
    },
    data () {
      return {
        QrSvg: null,
        QrSrc: null,
      }
    },
    methods: {
      handleQrCode: function (value) {
        let $this   = this;
        let options = {};
        if (this.showType == 'svg')
          options.type = 'svg';
        if (this.size != null)
          options.width = this.size;

        if (this.showType == 'svg')
          QrCode.toString(value, options, function (Err, Svg) {
            if (Err) console.error(Err);
            $this.QrSvg = Svg;
          });
        else
          QrCode.toDataURL(value, options, function (Err, Src) {
            if (Err) console.error(Err);
            $this.QrSrc = Src;
          });

      },
    },
    mounted: function () {
      if (this.value)
        this.handleQrCode(this.value);
    },
    watch: {
      value(value) {
        if (this.value)
          this.handleQrCode(value);
      }
    },
  };
</script>
