<template>
  <div v-if="ShowDownloadApp || ShowDownloadApk" class="idxDownload">
    <div class="container">
      <h2 class="title">
        <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/idx-title-download.webp`)" fluid alt="頂尖優質服務"></b-img>
      </h2>
      <div class="infoContent">
        <div class="qrCodeBox">
          <h3 class="boxTitle">
            <span class="icon">
              <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/icon-app-apple.webp`)" fluid alt="App Store"></b-img>
            </span>
            <span class="text">IOS Download</span>
          </h3>
          <div class="qrCode">
            <b-img v-show="!ShowDownloadApp" :src="require(`@/website/${DEF_webStyle}/images/pc/cover.webp`)" fluid alt="android qrcode"/>
            <QrCode v-show="ShowDownloadApp" v-model="DownloadAppUrl" class="share-qrcode"/>
          </div>

        </div>
        <div class="decImg">
          <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/app-img.webp`)" fluid alt="APP Download"></b-img>
        </div>
        <div class="qrCodeBox">
          <h3 class="boxTitle">
            <span class="icon">
              <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/icon-app-google.webp`)" fluid alt="Google Play"></b-img>
            </span>
            <span class="text">Android Download</span>
          </h3>
          <div class="qrCode">
            <b-img v-show="!ShowDownloadApk" :src="require(`@/website/${DEF_webStyle}/images/pc/cover.webp`)" fluid alt="android qrcode"/>
            <QrCode v-show="ShowDownloadApk" v-model="DownloadApkUrl" class="share-qrcode"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import QrCode from "~/components/common/qrCode.vue";
  export default {
    components: {
      QrCode,
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      DownloadAppUrl() {
        if (typeof window == 'undefined')
          return '';

        let Code        = '';
        let AgentCode   = this.$store.state.UpAgentCode
        let CookiesCode = this.$cookies.get("UpAgentCode");
        let StorageCode = localStorage.getItem('UpAgentCode');
        if (StorageCode && StorageCode != null && StorageCode != '')
          Code = StorageCode;
        if (CookiesCode && CookiesCode != null && CookiesCode != '')
          Code = CookiesCode;
        if (AgentCode && AgentCode != null && AgentCode != '')
          Code = AgentCode;

        return window.location.protocol + '//' + window.location.host + '/download/APP' + (Code != '' ? '?a=' + Code : '');
      },
      DownloadApkUrl() {
        if (typeof window == 'undefined')
          return '/';

        return window.location.protocol + '//' + window.location.host + this.GetDownloadApkUrl;
      },
      ...mapGetters('webSetting', ['ShowDownloadApp', 'ShowDownloadApk', 'GetDownloadApkUrl']),
    }
  };
</script>
