<template>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "autoRefresh",
  data() {
    return {
      Refresh: null,
    };
  },
  methods:{
    SetAutoRefresh() {
      if (this.Refresh)
        clearInterval(this.Refresh);

      this.Refresh = setInterval(() => {
        if (this.$auth.$state.loggedIn === true) this.getNureadNotice();
      }, 60000);
    },
    ...mapActions("webSetting", ["getNureadNotice"]),
  },
  mounted() {
    this.SetAutoRefresh();
    if (this.$auth.$state.loggedIn === true)
      this.getNureadNotice();
  }
};
</script>
