<template>
  <div>
    <b-modal
      id="modalMaintain"
      ref="myModalRef"
      centered
      :modal-class="myclass"
      hide-footer
      hide-header
    >
      <div class="modal-header">
        <div class="circle"><i class="icon icon-infomation"></i></div>
        <h5 class="title">{{ $t("store.uphold_time") }}</h5>
      </div>
      <div class="modal-main">
        <div
          class="maintain"
          v-for="(item, index) in $t('store.maintain_list')"
          :key="index"
        >
          <span>{{ item.title }}</span>
          <q>{{ item.date }}</q>
          <p>{{ item.time }}</p>
        </div>
        <b-button variant="submit" block @click="hideModal">{{
          $t("store.btn.close")
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      myclass: ["dialog-forgot"],
    };
  },
  methods: {
    showModal: function () {
      this.$refs.myModalRef.show();
    },
    hideModal() {
      this.$refs.myModalRef.hide();
    },
  }
};
</script>