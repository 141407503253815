<template>
  <div class="idxFooter">
    <div class="footer-gotop else" @click="scrollGoTop()">
      <i class="icon icon-arrowtop"></i>
    </div>
    <div class="footer-group footer__01">
      <ul class="footer-list">
        <li v-for="(item, index) in list" :key="index">
          <i :class="item.class"></i>
          <span>
            <b>{{ item.title }}</b>
            <q>{{ item.subtxt }}</q>
          </span>
        </li>
      </ul>
    </div>
    <div class="footer-group footer__02">
      <div class="footer-list gray">
        <div class="left">
          <h4>{{ $t("store.partner") }}</h4>
          <div class="footer-product" v-for="(image, index) in FooterProductLogo" :key="index">
            <template v-if="[42, 52].indexOf(image.id) >= 0">
              <img :src="require(`@/website/${DEF_webStyle}/images/game/logo/product_${image.id}.webp`)" :alt="image.name"/>
            </template>
            <template v-else>
              <img :src="require(`@/assets/all/images/logo/product_${image.id}.webp`)" :alt="image.name"/>
            </template>

          </div>
        </div>
        <div class="right">
          <a :href="WebSetting.TELRGRAM" target="_blank"><i class="icon icon-telegram"></i></a>
          <a :href="WebSetting.LINELINK" target="_blank"><i class="icon icon-line"></i></a>
        </div>
      </div>
    </div>
    <div class="footer-group footer__03">
      <div class="footer-link">
        <ul>
          <li v-for="(link, index) in idxFooterList" :key="index">
            <a v-if="link.blank" target="_blank" :href="link.url">{{ $t(link.name) }}</a>
            <nuxt-link v-else :to="link.url">{{ $t(link.name) }}</nuxt-link>
          </li>
        </ul>
        <p>{{ $t("store.footer_declare") }}</p>
        <p>{{ Copyright }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

var idxFooter = [];
try { idxFooter = require("$json/idxFooter.json"); } catch (e) {}

export default {
  data() {
    return {
      list: this.handleList(),
      idxFooter,
    };
  },
  methods: {
    scrollGoTop(){
      window.scrollTo({ top: 0 });
    },
    handleList() {
      return [
        {
          class: "icon icon-deposit",
          title: this.$t("store.footer01_title"),
          subtxt: this.$t("store.footer01_sub"),
        },
        {
          class: "icon icon-point",
          title: this.$t("store.footer02_title"),
          subtxt: this.$t("store.footer02_sub"),
        },
        {
          class: "icon icon-fast",
          title: this.$t("store.footer03_title"),
          subtxt: this.$t("store.footer03_sub"),
        },
        {
          class: "icon icon-diamond",
          title: this.$t("store.footer04_title"),
          subtxt: this.$t("store.footer04_sub"),
        },
        {
          class: "icon icon-evaluation",
          title: this.$t("store.footer05_title"),
          subtxt: this.$t("store.footer05_sub"),
        },
      ];
    },
    changeLang() {
      this.list = this.handleList();
    },
  },
  mounted() {
    this.changeLang();

  },
  watch: {
    "$i18n.locale"(language) {
      this.changeLang();
    },
  },
  computed: {
    DEF_webStyle() {
      return process.env.DEF_webStyle || 'default';
    },
    Copyright() {
      let str         = '';
      let MainContact = Object.keys(this.WebSetting).indexOf('MAIN_CONTACT') >= 0 ? this.WebSetting.MAIN_CONTACT : '';
      switch (MainContact) {
        case 'WHATSAPP':
          if (Object.keys(this.WebSetting).indexOf('WHATSAPPID') && this.WebSetting.WHATSAPPID)
            str = str + `Whats App ID : ${this.WebSetting.WHATSAPPID} / `;
          break;
        default:
          if (Object.keys(this.WebSetting).indexOf('LINEID') && this.WebSetting.LINEID)
            str = str + `LINE ID : ${this.WebSetting.LINEID} / `;
      }
      return str + `Copyright © ${this.WebSetting.WEBNAME} Co. Ltd. All rights reserved.`
    },
    idxFooterList () {
      return this.idxFooter.filter((Item) => {
        return Item.url != '/download/APP';
      }).map((Item) => {
        return Item;
      });
    },
    ...mapState(["WebSetting"]),
    ...mapGetters(["FooterProductLogo"]),
  },
};
</script>
