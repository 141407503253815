<template>
  <!-- 跑馬燈 -->
  <div class="desktop-mar">
    <div
      class="marquee"
      @click="toggleModal"
      ref="btnToggle"
    >
      <div class="icon icon-new"></div>
      <div class="mq-cont">
        <p :style="`animation-duration: ${seconds}s; -webkit-animation-duration: ${seconds}s;`">
          {{ AnnouncementStr }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  export default {
    name: "idxMarquee",
    data() {
      return {
        myclass: ["dialog-info"],
        seconds: 20,
      };
    },
    methods: {
      showModal: function () {
        this.$refs.myModalRef.show();
      },
      toggleModal() {
        this.$root.$emit("bv::toggle::modal", "modalInfo", "#btnToggle");
      },
      getData() {
        this._getAnnouncement().then(() => { this.setSeconds(); });
      },
      setSeconds() {
        if (!this.Announcement || this.Announcement.length == 0)
          return;

        let num = this.Announcement.map((item) => item.content).join("&nbsp;&nbsp;&nbsp;&nbsp;").replace(/\s*/g,"").length;
        let s   = parseInt(num / 200) + 1;
        this.seconds = s * (this.DEF_webStyle == 'fl88' ? 45 : 30);
      },
      ...mapActions(["_getAnnouncement"]),
    },
    mounted: function () {
      this.getData();
    },
    watch: {
      Announcement() {
        this.setSeconds();
      },
    },
    computed: {
      AnnouncementStr() {
        if (!this.Announcement || this.Announcement.length == 0)
          return '';

        return this.Announcement.map((item) => item.content).join("            ");
      },
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ...mapState(["Announcement"]),
    },
  };
</script>
