<template>
  <div class="idx jackpot">
    <transition name="fade">
      <div class="jackpot-title">
        <span class="jackpot-img"></span>
        <i :class="`jackpot-coin coin${i}`" v-for="i in 7"></i>
      </div>
      </transition>
      <div class="jackpot-num">
        <div class="GO" ref="carousel"
          data-auto="true"
          data-delay="5000"
          @mouseenter.stop="toggleTimer = false"
          @mouseleave.stop="toggleTimer = true"
          :style="'min-height:' + minHeight"
        >
          <keep-alive>
            <transition :name="carouselName">
              <div class="slider" v-for="(item, index) in carousels" :key="index" v-if="show == index">
                <div v-for="(carousels, index) in item.districts">
                  <span class="slider-user">{{ carousels.user }}</span> {{ $t("store.jackpot_in") }}<q>{{ carousels.product }} - {{ carousels.name }}</q>{{ $t("store.jackpot_geting") }} <span class="slider-money">{{ carousels.money | currency }}</span> {{ $t("store.jackpot_dollar") }}
                </div>
              </div>
            </transition>
          </keep-alive>
			  </div>
      </div>
      <div class="jackpot-bg"></div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
	data(){
		return{
			carouselName: "fade",
			len: 0,
			show: 0,
			autoplay: false, // 是否自動輪播
			timer: null, // auto play
			timerDelay: 3000, // 自動輪播間隔秒數
			toggleTimer: true, // pause auto play
			minHeight: 0,
			carouselsLen: 6,
      carousels: [],
		}
	},
	methods:{
    getData() {
      this._getJackpot().then(() => {
        if (screen.width <= 320) this.carouselsLen = 3;
        this.carousels  = this.setCarousels();
        this.len        = this.carousels.length;
        this.autoplay   = this.$refs.carousel.dataset.auto == 'true';
        this.timerDelay = Number(this.$refs.carousel.dataset.delay) || 3000;
        if (this.autoplay)
          this.autoPlay();

        window.addEventListener('load', () => {
          let refCarousel = this.$refs.carousel;
          if (refCarousel)
            this.minHeight = this.$refs.carousel.offsetHeight + 'px';
        });
      })
    },
		toNext() {
			this.carouselName = 'carousel-next';
			if(this.show + 1 >= this.len){
				this.show = 0
			}else{
				this.show = this.show + 1;
			}
		},
		autoPlay() {
			setInterval(() => {
				if(this.toggleTimer) this.toNext();
			}, this.timerDelay);
		},
    setCarousels() {
      let tmp  = [];
      let data = [];
      this.Jackpot.forEach((item, index) => {
        if (tmp.length >= this.carouselsLen) {
          data.push({districts : tmp});
          tmp = [];
        }
        tmp.push(item);
      });
      if (tmp.length > 0)
        data.push({districts : tmp});

      return data;
    },
    ...mapActions(["_getJackpot"]),
	},
  mounted: function () {
    this.$nextTick(function () {
      this.getData();
    });
	},
  computed: {
      ...mapState(['Jackpot']),
  }
}
</script>
