<template>
  <div>
    <b-modal
      id="modalExchPoint"
      ref="myModalRef"
      :modal-class="myclass"
      hide-footer
      hide-header
      @hidden="onClose"
    >
      <div class="modal-header" @click="audioClick">
        <div class="circle"><i class="icon icon-turning"></i></div>
        <h5 class="title">{{ $t("store.transfer.points_records") }}</h5>
        <b-button class="close" @click="onClose"></b-button>
      </div>

      <b-form
        v-show="ShowTransferModal || !ShowPlayBut"
        @submit.stop.prevent="onSubmit"
      >
        <div class="modal-main">

          <b-alert variant="danger" show v-show="ShowPointTip">
            <i class="icon icon-infomation"></i>
            <span>{{ $t("store.wallet.enter_ob_tip", { game: PointName }) }}</span>
          </b-alert>

          <b-alert variant="danger" show v-show="ShowPointTip10">
            <i class="icon icon-infomation"></i>
            <span>{{ $t("store.wallet.enter_exch_tip_10", { game: PointName }) }}</span>
          </b-alert>

          <div class="chooseA" @click="audioClick">
            <p>{{ $t("store.transfer.iwant") }}</p>
            <b-form-select v-model="form.from_id" :state="validateState('from_id')" :options="WalletGroup(null, WalletCategory)">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>{{ $t("store.wallet.plz_choose_out") }}</b-form-select-option>
              </template>
            </b-form-select>
            <b-spinner v-if="FromWallet == 'loading'" small variant="variant" type="grow" label="Spinning"></b-spinner>
            <span v-else-if="FromWallet == 'maintain'" style="color: red">{{ $t("store.uphold_ing") }}</span>
            <span v-else>${{ FromWallet }}</span>
            <b-form-invalid-feedback>{{ $t("store.wallet.plz_choose_out") }}</b-form-invalid-feedback>
          </div>

          <div class="modal-to">
            <i class="icon icon-arrowinto"></i>
          </div>

          <div class="chooseB" @click="audioClick">
            <p>{{ $t("store.transfer.goto") }}</p>
            <b-form-select v-model="form.to_id" :state="validateState('to_id')" :options="WalletGroup(form.from_id, WalletCategory)">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>{{ $t("store.wallet.plz_choose_in") }}</b-form-select-option>
              </template>
            </b-form-select>
            <b-spinner v-if="ToWallet == 'loading'" small variant="variant" type="grow" label="Spinning"></b-spinner>
            <span v-else-if="ToWallet == 'maintain'" style="color: red">{{ $t("store.uphold_ing") }}</span>
            <span v-else >${{ ToWallet }}</span>
            <b-form-invalid-feedback>{{ $t("store.wallet.plz_choose_in") }}</b-form-invalid-feedback>
          </div>

          <b-form-group :label="$t('store.transfer.enterpoint')">
            <b-form-input v-model="$v.form.point.$model" :state="validateState('point')" @click="audioClick" :placeholder="$t('store.transfer.enterpoint')" trim> </b-form-input>
            <b-form-invalid-feedback>{{ $t("store.transfer.onlyInteger") }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :class="groupclass">
            <b-button v-for="(tag, index) in tagItem" :key="index" @click="setPoints(tag.value)" variant="badge" >
              <span v-if="tag.value == 'All'">{{ tag.itemTag }}</span>
              <span v-else>{{ tag.itemTag | currencyInt }}</span>
            </b-button>
          </b-form-group>
        </div>

        <div class="modal-footer col-12">
          <!-- <b-button block pill class="btn-cancel" variant="cancel" @click="onClose">
            {{ $t("store.btn.cancel") }}
          </b-button> -->
          <b-button block pill class="btn-turnpoint" type="submit" variant="submit" @click="audioClick">
            {{ $t("store.transfer.turn_point") }}
          </b-button>
          <b-button v-if="ShowPlayBut" block pill class="btn-submit" variant="info" @click="openGameUrl(0)">
            {{ $t("store.btn.enter_game") }}
          </b-button>
          <b-button block pill variant="backwallet" @click="allAccountTransfer">
            {{ $t("store.wallet.back_wallet") }}
          </b-button>
        </div>

        <b-overlay :show="isSubmit" no-wrap>
          <template v-slot:overlay>
            <div class="loading text-center">
              <div class="lds-square"></div>
              <div class="text">Loading...</div>
            </div>
          </template>
        </b-overlay>
      </b-form>

      <b-form
        v-show="!ShowTransferModal && ShowPlayBut"
      >
        <div class="modal-main">
          <b-alert variant="danger" show v-show="ShowPointTip">
            <i class="icon icon-infomation"></i>
            <span>{{ $t("store.wallet.enter_ob_tip", { game: PointName }) }}</span>
          </b-alert>

          <b-alert variant="danger" show v-show="ShowPointTip10">
            <i class="icon icon-infomation"></i>
            <span>{{ $t("store.wallet.enter_exch_tip_10", { game: PointName }) }}</span>
          </b-alert>
        </div>

        <div class="modal-footer col-12">
          <b-button v-if="ShowPlayBut" block pill class="btn-submit" variant="info" @click="openGameUrl(0)">
            {{ $t("store.btn.enter_game") }}
          </b-button>
        </div>

      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
  import { validationMixin } from "vuelidate";
  import { required, numeric, minValue } from "vuelidate/lib/validators";

  export default {
    mixins: [validationMixin],
    components: {},
    data() {
      return {
        live: false,
        FromWallet: this.handleFormWallet("from_id"),
        ToWallet: this.handleFormWallet("to_id"),
        isSubmit: false,
        needOpen: true,
        tagItem: this.handleTagItem(),
        myclass: ["dialog-exchpoint"],
        groupclass: ["badge-group"],
        QueueList: [],
        form: {
          from_id: null,
          to_id: null,
          point: null,
        },

      };
    },
    validations: {
      form: {
        from_id: { required },
        to_id: { required },
        point: { required, numeric, minValue: minValue(1) },
      },
    },
    methods: {
      audioClick() {
        if(this.DEF_webStyle === 'jdf'|| this.DEF_webStyle === 'fl8n'|| this.DEF_webStyle === 'fl88'|| this.DEF_webStyle === 'kb88'){
          if(this.IsAudio) {
            const audio = document.querySelector("#beep");
            const context = new AudioContext();

            if (context.state === 'suspended') {
              context.resume().then(() => {});
            };
            audio.muted = false;
            audio.play();
          }
        };
      },
      showModal: function () {
        this.$refs.myModalRef.show();
      },
      hideModal() {
        this.$refs.myModalRef.hide();
      },
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      onSubmit(evt) {
        evt.preventDefault();

        this.$v.form.$touch();
        if (this.$v.form.$anyError)
          return;
        if (this.isSubmit)
          return;

        this.isSubmit = true;
        this.AccountTransfer(this.form).then((res) => {
          if (res) {
            this.GetAccountWallet(this.form.from_id).then(() => this.ChangeFormWallet());
            this.GetAccountWallet(this.form.to_id).then(() => this.ChangeFormWallet());
            this.onReset();
            this.openGameUrl(0);
          } else {
            this.needOpen = false;
          }
          this.isSubmit = false;
        });
      },
      onReset() {
        Object.keys(this.form).forEach(element => { this.form[element] = null; });
        this.$nextTick(() => { this.$v.$reset(); });
      },
      onClose() {
        this.audioClick();
        this.onReset();
        this.$root.$emit("bv::hide::modal", "modalExchPoint", "#btnToggle");
        this.setPlayGameData(null);
      },
      openGameUrl(delay) {
        this.audioClick();
        if (this.PlayGameData && Object.keys(this.PlayGameData).length > 0) {
          let $this = this;
          let GameUrl = this.PlayGameData.Url;
          this.setPlayGameData(null);
          setTimeout(function () {
            window.open(GameUrl);
            $this.$root.$emit("bv::hide::modal", "modalExchPoint", "#btnToggle");
          }, delay * 1000);
        }
      },
      setPoints(Point) {
        if (this.form.from_id === null && Point == "All")
          this.form.point = 0;
        else
          this.form.point = Point == "All" ? parseInt(this.AccountWallet[this.form.from_id]) : parseInt(Point);
      },
      refreshWallet() {
        for (let item in this.AccountWallet) {
          this.GetAccountWallet(item).then(() => this.ChangeFormWallet());
        }
      },
      refreshWallet() {
        for (let Item in this.AccountWallet) {
          if (this.QueueList.indexOf(Item) < 0)
            this.QueueList.push(Item);
        }

        this.$nextTick(function() {
          this.handleQueue();
          this.handleQueue();
          this.handleQueue();
          this.handleQueue();
        });
      },
      handleQueue() {
        if (this.QueueList.length == 0)
          return;

        let WalletId = this.QueueList.shift();
        this.GetAccountWallet(WalletId).finally((res) => {
          this.ChangeFormWallet();
          this.handleQueue();
        });
      },
      allAccountTransfer() {
        this.audioClick();
        if (this.isSubmit)
          return;

        this.isSubmit = true;
        this.AllAccountTransfer(this.form).then((res) => {
          if (res)
            this.refreshWallet();

          this.isSubmit = false;
        });
      },
      handleFormWallet(WalletStr) {
        let field = null;
        if (this.form && Object.keys(this.form).indexOf(WalletStr) >= 0)
          field = this.form[WalletStr];

        if (!field && field != 0)
          return 0;

        if (Object.keys(this.AccountWalletLoading).indexOf(field.toString()) >= 0 && this.AccountWalletLoading[field])
          return "loading";

        if (Object.keys(this.AccountWalletData).indexOf(field.toString()) >= 0 && this.AccountWalletData[field].maintain == "1")
          return "maintain";

        if (Object.keys(this.AccountWallet).indexOf(field.toString()) >= 0)
          return this.AccountWallet[field];

        return 0;
      },
      ChangeFormWallet() {
        this.FromWallet = this.handleFormWallet("from_id");
        this.ToWallet   = this.handleFormWallet("to_id");
      },
      handleTagItem() {
        return [
          { itemTag: "1000", value: "1000" },
          { itemTag: "2000", value: "2000" },
          { itemTag: "5000", value: "5000" },
          { itemTag: this.$t("store.all"), value: "All" },
        ];
      },
      changeLang() {
        this.tagItem = this.handleTagItem();
      },
      ...mapActions("game", ["AccountTransfer", "AllAccountTransfer"]),
      ...mapActions("account", ["GetAccountWallet"]),
      ...mapMutations("game", ["setPlayGameData"]),
      ...mapMutations("account", ["setAccountWalletData"]),
    },
    mounted() {;
      this.changeLang();
    },
    watch: {
      "$v.form.from_id.$model"(value) {
        if (!value && value != 0) return this.ChangeFormWallet();

        if (value == this.$v.form.to_id.$model || (value != 0 && this.$v.form.to_id.$model != 0))
          this.$v.form.to_id.$model = null;

        this.GetAccountWallet(value).then(() => this.ChangeFormWallet());
      },
      "$v.form.to_id.$model"(value) {
        if (!value && value != 0)
          return this.ChangeFormWallet();

        this.GetAccountWallet(value).then(() => this.ChangeFormWallet());
      },
      PlayGameData(value) {
        if (!value) return;
        if (Object.keys(this.WebSetting).indexOf("Wallet") >= 0) {
          let WalletData = this.WebSetting.Wallet.find(
            (element) => element.code === value.WalletCode
          );
          if (WalletData) {
            this.$set(this.form, "from_id", "0");
            this.$set(this.form, "to_id", WalletData.id);
            this.needOpen = true;
          }
        }
      },
      "$i18n.locale"(language) {
        this.changeLang();
      },
    },
    computed: {
      ShowPlayBut() {
        return this.PlayGameData && Object.keys(this.PlayGameData).length > 0;
      },
      ShowPointTip() {
        return [24, 25, 26, 27, 28, 44, 47, 78].indexOf(this.form.from_id) >= 0 || [24, 25, 26, 27, 28, 44, 47, 78].indexOf(this.form.to_id) >= 0;
      },
      ShowPointTip10() {
        return [62].indexOf(this.form.from_id) >= 0 || [62].indexOf(this.form.to_id) >= 0;
      },
      PointName() {
        if ([24, 25, 26, 27, 28, 44, 47, 62, 78].indexOf(this.form.from_id) >= 0)
          return this.WebSetting.Wallet.find(element => element.id === this.form.from_id).name || '';
        if ([24, 25, 26, 27, 28, 44, 47, 62, 78].indexOf(this.form.to_id) >= 0)
          return this.WebSetting.Wallet.find(element => element.id === this.form.to_id).name || '';
        else
          return '';
      },
      WalletCategory() {
        let Data = { category_0: {label: this.$t("store.wallets"), options: [] }};
        this.Category.forEach(element => {
          Data[`category_${element.id}`] = {label: this.$t(element.title), options: [] }
        });
        return Data;
      },
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ...mapState(["WebSetting", "IsAudio"]),
      ...mapState("game", ["PlayGameData"]),
      ...mapState("account", ["AccountWallet", "AccountWalletData", "AccountWalletLoading"]),
      ...mapGetters(["Wallet", 'WalletGroup', 'Category']),
      ...mapGetters("account", ["AccountWalletOne"]),
      ...mapGetters('webSetting', ['ShowTransferModal'])
    }
  };
</script>
